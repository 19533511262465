import React from 'react'
import weibo from './svgs/weibo.svg'
import instagram from './svgs/instagram.svg'
import bilibili from './svgs/bilibili.svg'
import zcool from './svgs/zcool.svg'
import behance from './svgs/behance.svg'
import email from './svgs/email.svg'

import './styles.scss'

let titleDict = {
  WEIBO: { arrowSrc: weibo, jumpTo: 'https://weibo.com/u/1761423110' },
  INSTAGRAM: { arrowSrc: instagram, jumpTo: 'https://instagram.com/guanyenc' },
  BILIBILI: { arrowSrc: bilibili, jumpTo: 'https://b23.tv/G4MugT' },
  ZCOOL: { arrowSrc: zcool, jumpTo: 'https://www.zcool.com.cn/u/14566425' },
  BEHANCE: { arrowSrc: behance, jumpTo: 'https://www.behance.net/guanyenc' },
  EMAIL: { arrowSrc: email, jumpTo: 'mailto:guanyenc996@gmail.com' },
}

const ContactWay = (props) => {
  let { arrowSrc, jumpTo } = titleDict[props.title]

  let row = 'row'
  if (props.towards === 'toRight') {
    row = 'row-reverse'
  }
  return (
    <div className='contactWay' style={{ flexDirection: row }}>
      <a href={jumpTo} target='_blank' rel='noopener noreferrer'>
        <div className='title'>{props.title}</div>
      </a>
      <img src={arrowSrc} className='arrow' alt={props.title} />
    </div>
  )
}

ContactWay.defaultProps = {
  title: 'WEIBO',
  towards: 'toLeft',
}

export default ContactWay
