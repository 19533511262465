import React from 'react'
import { Helmet } from 'react-helmet'
import ContactWay from '../components/contactWay'


const ContactMe = () => {
  return (
    <main>
      <Helmet>
        <title>Guanyenc contact me</title>
      </Helmet>
      <div className='contactPage'>
        <div className='pageTitle' >
          Contact Me
        </div>

        <ContactWay title={'WEIBO'} />
        <ContactWay title={'INSTAGRAM'} towards='toRight' />
        <ContactWay title={'BILIBILI'} />
        <ContactWay title={'ZCOOL'} towards='toRight' />
        <ContactWay title={'BEHANCE'} />
        <ContactWay title={'EMAIL'} towards='toRight' />
      </div>
    </main>
  )
}

export default ContactMe
